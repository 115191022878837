import AWS from 'aws-sdk';
import StepFunctions from 'aws-sdk/clients/stepfunctions'; // インスタンスのインポート
import EventBridge from 'aws-sdk/clients/eventbridge'; // EventBridgeインスタンスのインポート
import cronstrue from 'cronstrue'; // cronstrueのインポート

// 環境ごとの設定を読み込む
const env = process.env.REACT_APP_ENV || 'dev';
const config = require(`../config/${env}`).default;

AWS.config.update({
  region: config.region,
  accessKeyId: config.accessKeyId,
  secretAccessKey: config.secretAccessKey
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();
const stepFunctions = new StepFunctions();
const eventBridge = new EventBridge();

export const awsAccountId = config.awsAccountId;

export const fetchStatuses = async () => {
  const params = {
    TableName: config.dynamoDBTableName
  };

  try {
    const data = await dynamoDB.scan(params).promise();
    console.log('Fetched statuses:', data.Items); // データ取得のログ

    // Step Functionsからステートマシン名を取得
    const stepFunctionsParams = {
      maxResults: 1000 // 必要に応じて調整
    };

    const stepFunctionsData = await stepFunctions.listStateMachines(stepFunctionsParams).promise();
    console.log('Fetched state machines:', stepFunctionsData.stateMachines); // データ取得のログ

    // ステートマシン名をジョブステータスにマッピング
    const stateMachineMap = stepFunctionsData.stateMachines.reduce((map, item) => {
      const prefix = env === 'prd' ? 'prd' : (env === 'stg' ? 'stg' : 'dev'); // 環境に応じたプリフィックスを設定
      const regex = new RegExp(`${prefix}([A-Za-z0-9]+xx)`); // 正規表現を動的に設定
      const match = item.name.match(regex);
      if (match) {
        const jobId = `JobNet${match[1].slice(0, -2)}`;
        map[jobId] = item.name;
      }
      return map;
    }, {});

    console.log('State Machine Map:', stateMachineMap); // マッピングのログ

    // 各ステータスにステートマシン名を追加
    data.Items.forEach(item => {
      item.stateMachineName = stateMachineMap[item.jobId];
      if (!item.stateMachineName) {
        console.warn(`No state machine found for jobId: ${item.jobId}`); // デバッグ用の警告
      }
    });

    // EventBridgeのルールを取得し、スケジュールをマッピング
    const ruleNames = data.Items.map(item => {
      const match = item.jobId.match(/JobNet(\d{3}|T\d{2})/); // 3桁の数字またはT+2桁の数字を抽出
      if (match) {
        const jobNumber = match[1]; // 3桁の数字またはT+2桁の部分を抽出
        return `TaikoJobStack-${env}-${env}${jobNumber}xxschedule`;
      } else {
        console.warn(`Job ID does not match expected pattern: ${item.jobId}`);
        return null;
      }
    }).filter(ruleName => ruleName !== null); // 無効なルール名を除外

    console.log('Generated Rule Names:', ruleNames); // 生成されたルール名のログ

    const rules = await Promise.all(ruleNames.map(async ruleName => {
      const ruleParams = {
        NamePrefix: ruleName
      };
      console.log('EventBridge ruleParams:', ruleParams); // デバッグ用にパラメータをログ出力
      const ruleData = await eventBridge.listRules(ruleParams).promise();
      console.log('EventBridge ruleData:', ruleData); // デバッグ用にレスポンスをログ出力
      return ruleData.Rules[0];
    }));

    console.log('EventBridge Rules:', rules); // デバッグ用に取得したルールをログ出力

    const ruleMap = rules.reduce((map, rule) => {
      if (rule) {
        const ruleKey = rule.Name.split('xxschedule')[0]; // ルール名のプレフィックス部分をキーに設定
        map[ruleKey] = rule; // 修正: ルールオブジェクト全体を設定
      }
      return map;
    }, {});

    console.log('Event Rule Map:', ruleMap); // イベントルールのマッピングログ

    // 各ステータスにスケジュールとルール名を追加
    data.Items.forEach(item => {
      const match = item.jobId.match(/JobNet(\d{3}|T\d{2})/); // 3桁の数字またはT+2桁の数字を抽出
      if (match) {
        const jobNumber = match[1]; // 3桁の数字またはT+2桁の部分を抽出
        const ruleKey = `TaikoJobStack-${env}-${env}${jobNumber}`;
        const rule = ruleMap[ruleKey];
        const scheduleExpression = rule ? rule.ScheduleExpression : null;
        console.log('Rule Key:', ruleKey, 'Schedule Expression:', scheduleExpression); // デバッグ用にログ出力
        item.schedule = scheduleExpression ? parseCronExpression(scheduleExpression) : 'N/A';
        item.ruleName = rule ? rule.Name : 'N/A'; // 修正: 正しいルール名を設定
      } else {
        item.schedule = 'N/A';
        item.ruleName = 'N/A';
      }
    });

    return data.Items;
  } catch (error) {
    console.error('Error fetching statuses:', error);
    console.error('Error details:', error); // エラーの詳細をログに出力
    return [];
  }
};

// cron形式を人間が読みやすい形式に変換する関数
const parseCronExpression = (expression) => {
  try {
    // 'cron(' と ')' を取り除く
    const cronExpression = expression.replace('cron(', '').replace(')', '');
    console.log('cronExpression:', cronExpression);
    let humanReadable = cronstrue.toString(cronExpression, { locale: 'en' });
    if (humanReadable.length > 20) {
      humanReadable = humanReadable.substring(0, 20) + '...';
    }
    return humanReadable;
  } catch (err) {
    console.error('Error parsing cron expression:', expression, err);
    return 'Invalid cron expression';
  }
};

export const updateStatus = async (jobId, isRunning) => {
  const params = {
    TableName: config.dynamoDBTableName,
    Key: {
      jobId: jobId
    },
    UpdateExpression: 'set isRunning = :r',
    ExpressionAttributeValues: {
      ':r': isRunning
    }
  };

  try {
    await dynamoDB.update(params).promise();
  } catch (error) {
    console.error('Error updating status:', error);
  }
};

