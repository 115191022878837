import React, { useState, useEffect } from 'react';
import { fetchStatuses, updateStatus, awsAccountId } from './aws-config';
import './App.css';

function App() {
  const [statuses, setStatuses] = useState([]);
  const env = process.env.REACT_APP_ENV || 'dev'; // 環境を取得

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStatuses();
        data.sort((a, b) => {
          const numA = parseInt(a.jobId.replace(/^\D+/g, '')); // 数字部分を抽出
          const numB = parseInt(b.jobId.replace(/^\D+/g, '')); // 数字部分を抽出
          return numA - numB;
        });
        setStatuses(data);
        console.log('Statuses updated:', data); // データ取得のログ
      } catch (error) {
        console.error('Error in fetchData:', error); // エラーログ
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // 1分毎にデータを取得
    return () => clearInterval(interval);
  }, [env]);

  const getStatusColor = (isRunning) => {
    switch (isRunning) {
      case 0:
        return 'green';
      case 1:
        return 'blue'; // 色を青に変更
      case 2:
        return 'red';
      default:
        return 'black';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');
    return `${yyyy}/${mm}/${dd} ${hh}:${min}:${ss}`;
  };

  const handleRestart = async (jobId) => {
    const confirmRestart = window.confirm('再開しますか？');
    if (confirmRestart) {
      try {
        await updateStatus(jobId, 0); // Running Statusを0（正常）に変更
        const updatedStatuses = statuses.map(status => 
          status.jobId === jobId ? { ...status, isRunning: 0 } : status
        );
        setStatuses(updatedStatuses);
        console.log('Status updated for jobId:', jobId); // ステータス更新のログ
      } catch (error) {
        console.error('Error in handleRestart:', error); // エラーログ
      }
    }
  };

  const getStateMachineUrl = (jobId, stateMachineName) => {
    const encodedStateMachineName = encodeURIComponent(stateMachineName);
    return `https://ap-northeast-1.console.aws.amazon.com/states/home?region=ap-northeast-1#/statemachines/view/arn%3Aaws%3Astates%3Aap-northeast-1%3A${awsAccountId}%3AstateMachine%3A${encodedStateMachineName}?type=%E6%A8%99%E6%BA%96`;
  };

  const getEventBridgeUrl = (ruleName) => {
    return `https://ap-northeast-1.console.aws.amazon.com/events/home?region=ap-northeast-1#/eventbus/default/rules/${ruleName}`;
  };

  const appStyle = env === 'prd' ? { backgroundColor: '#ffe6e6' } : (env === 'stg' ? { backgroundColor: '#e6f7ff' } : {}); // prdは淡いピンク、stgは淡い水色

  return (
    <div className="App" style={appStyle}>
      <header className="App-header">
        <h1>{env} Job Status Table</h1> {/* 環境名を追加 */}
        <table>
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Event Schedule</th> {/* 新しい列を追加 */}
              <th>Running Status</th>
              <th>Last Updated Date</th>
              <th>Actions</th>
              <th>StateMachine</th>
            </tr>
          </thead>
          <tbody>
            {statuses.map(status => (
              <tr key={status.jobId} style={!status.stateMachineName ? { backgroundColor: '#d3d3d3' } : {}}>
                <td>{env}{status.jobId}</td> {/* 環境名をJob IDに追加 */}
                <td>
                  {status.schedule !== 'N/A' ? (
                    <a href={getEventBridgeUrl(status.ruleName)} target="_blank" rel="noopener noreferrer">
                      {status.schedule}
                    </a>
                  ) : 'N/A'}
                </td>
                <td style={{ color: getStatusColor(status.isRunning) }}>
                  {status.isRunning === 0 && '正常'}
                  {status.isRunning === 1 && (
                    <React.Fragment>
                      <span>起動中</span>
                      <i className="fas fa-spinner fa-spin"></i>
                    </React.Fragment>
                  )}
                  {status.isRunning === 2 && '異常'}
                </td>
                <td>{formatDate(status.lastUpdatedDate)}</td>
                <td>
                  <button
                    onClick={() => handleRestart(status.jobId)}
                    disabled={status.isRunning !== 2}
                    style={{
                      backgroundColor: status.isRunning !== 2 ? 'lightgray' : 'initial',
                      cursor: status.isRunning !== 2 ? 'not-allowed' : 'pointer'
                    }}
                  >
                    再開
                  </button>
                </td>
                <td>
                  {status.stateMachineName ? (
                    <a
                      href={getStateMachineUrl(status.jobId, status.stateMachineName)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </header>
    </div>
  );
}

export default App;

